<template >
    <div>
        <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
            <div class="app-modal__in">
                <div
                    class="app-modal__header d-flex f-between p-5 m-title-modal"
                >
                    <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">
                        {{
                            $t("message.new_m", {
                                m: $t("message.currency_y"),
                            })
                        }}
                    </p>
                    <div>
                        <crm-store-update-close
                            :permission="$options.name"
                            :button_type="'store'"
                            :loading="loadingButton"
                            @c-submit="submit(true)"
                            @c-close="close()"
                        ></crm-store-update-close>
                    </div>
                </div>
            </div>
            <!-- app-modal__header end -->

            <div class="app-modal__body p-5 pb-0">
                <div class="timeline-items__right rounded-sm w-100 p-4" :class="mode ? 'table__myday' : 'table__mynight'">
                    <el-form
                        ref="form"
                        status-icon
                        :model="form"
                        :rules="rules"
                    >
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <!-- <span class="input--label d-block mb-2">
                                    {{ $t("message.name") }}
                                </span>
                                <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="name">
                                    <crm-input
                                        :placeholder=" $t('message.name') "
                                        :inputValue="form.name"
                                        v-model="form.name"
                                        :size="'medium'"
                                    ></crm-input>
                                </el-form-item> -->

                                <el-form-item :class="mode ? 'input__day' : 'input__night'"
                                    :label="$t('message.nameGiven')"
                                    prop="name"
                                    class="label_mini"
                                >
                                    <crm-input
                                        :size="'medium'"
                                        :inputValue="form.name"
                                        v-model="form.name"
                                        :placeholder="$t('message.nameGiven')"
                                    ></crm-input>
                                </el-form-item>
                            </el-col>
                            <!-- end col -->
                           
                            <el-col :span="12">
                                <!-- <span class="input--label d-block mb-2">
                                    {{ $t("message.code") }}
                                </span>
                                <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="code">
                                    <crm-input
                                        :placeholder="'UZB'"
                                        :inputValue="form.code"
                                        v-model="form.code"
                                        :size="'medium'"
                                    ></crm-input>
                                </el-form-item> -->

                                <el-form-item :class="mode ? 'input__day' : 'input__night'"
                                    :label="$t('message.letter_currency_code')"
                                    prop="code"
                                    class="label_mini"
                                >
                                    <crm-input
                                        :size="'medium'"
                                        :inputValue="form.code"
                                        v-model="form.code"
                                        :placeholder="'UZB'"
                                    ></crm-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                              <el-form-item :class="mode ? 'input__day' : 'input__night'"
                                  :label="$t('message.numeric_currency_code')"
                                  prop="code"
                                  class="label_mini"
                              >
                                  <crm-input
                                      :size="'medium'"
                                      :inputValue="form.numeric_currency_code"
                                      v-model="form.numeric_currency_code"
                                      :placeholder="'123'"
                                  ></crm-input>
                              </el-form-item>
                          </el-col>

                            <el-col :span="12">
                                <!-- <span class="input--label d-block mb-2">
                                    {{ $t("message.symbol") }}
                                </span>
                                <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="symbol">
                                    <crm-input
                                        :placeholder="'som'"
                                        :inputValue="form.symbol"
                                        v-model="form.symbol"
                                        :size="'medium'"
                                    ></crm-input>
                                </el-form-item> -->

                                <el-form-item :class="mode ? 'input__day' : 'input__night'"
                                    :label="$t('message.symbol')"
                                    prop="symbol"
                                    class="label_mini"
                                >
                                    <crm-input
                                        :size="'medium'"
                                        :inputValue="form.symbol"
                                        v-model="form.symbol"
                                        :placeholder="$t('message.symbol')"
                                    ></crm-input>
                                </el-form-item>
                            </el-col>
                            <!-- end col -->
                            <!-- <el-col :span="12">
                                <span class="input--label d-block mb-2">
                                    {{ $t("message.currency_y") }}
                                </span>
                                <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="rate">
                                    <div style="margin-top: 15px; display:flex" >
                                      <span class="input--label d-block mb-2">
                                        {{ $t("message.currency_y") }}
                                    </span>
                                      <el-input placeholder="Please input" v-model="input2" style="border-radius:0">
                                        <template slot="append">SUM</template>
                                      </el-input>
                                    </div>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="12">
                              <el-form-item :class="mode ? 'input__day' : 'input__night'" label="">
                                  <el-checkbox
                                      v-model="form.reverse"
                                      :label="'Обратный курс'"
                                  ></el-checkbox>
                              </el-form-item>
                          </el-col>
                          <el-col :span="12">
                              <el-form-item :class="mode ? 'input__day' : 'input__night'"
                                  class="add-label"
                                  v-if="!form.reverse"
                                  :label="getLabel"
                              >
                                  <el-input
                                      type="number"
                                      v-model="form.rate"
                                      autocomplete="off"
                                      style="width: 250px !important"
                                  >
                                      <i slot="append">{{ getAppendText }}</i>
                                  </el-input>
                              </el-form-item>
                         
                              <el-form-item :class="mode ? 'input__day' : 'input__night'"
                                  class="add-label"
                                  v-if="form.reverse"
                                  :label="getLabel"
                              >
                                  <el-input
                                      type="number"
                                      v-model="form.reversed_rate"
                                      autocomplete="off"
                                      style="width: 250px !important"
                                  >
                                      <i slot="append">{{ getAppendText }}</i>
                                  </el-input>
                              </el-form-item>
                          </el-col>  
                            <!-- <el-col :span="12">
                                <span class="input--label d-block mb-2">
                                    {{ $t("message.okurs") }}
                                </span>
                                <el-form-item :class="mode ? 'input__day' : 'input__night'" prop="is_active">
                                    <el-switch
                                        v-model="form.delivery"
                                    ></el-switch>
                                </el-form-item>
                            </el-col> -->
                        </el-row>
                    </el-form>
                </div>
            </div>
            <!-- end app-modal__body -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
/**
 * Drawer chaqirish kerak drawerni yopish uchun
 */
import form from "@/utils/mixins/form";
export default {
    mixins: [form, drawer],
    name: "CurrencyController",
    data() {
        return {};
    },
    mounted() {
        if (!_.size(this.currencies)) this.updateCurrencyInventory();
    },
    created() {},
    watch: {
        'form.reversed_rate': {
            handler: function() {
                if (this.form.reverse) {
                    this.form.rate = _.round(_.divide(1, this.form.reversed_rate), 20)
                }
            }
        },
        'form.rate': {
            handler: function() {
                if (!this.form.reverse) {
                    this.form.reversed_rate = _.round(_.divide(1, this.form.rate), 20);
                }
            }
        }
    },
    computed: {
        ...mapGetters({
            rules: "currency/rules",
            model: "currency/model",
            columns: "currency/columns",
            currencies: 'currency/inventory',
            mode: "MODE"
        }),
        getLabel: function() {
            let str = '';
            if (this.form.reverse) {
                let active_currency = _.find(this.currencies, 'is_active');
                if (active_currency) {
                    str = active_currency.code;
                }
            } else {
                str = this.form.code;
            }
            return ('1 ' + str + ' =');
        },
        getAppendText: function() {
            let str = '';
            if (!this.form.reverse) {
                let active_currency = _.find(this.currencies, 'is_active');
                if (active_currency) {
                    str = active_currency.code;
                }
            } else {
                str = this.form.code;
            }
            return str;
        }
    },
    methods: {
        ...mapActions({
            save: "currency/store",
            updateCurrencyInventory: 'currency/inventory'
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
        // getLabel() {
        //     let str = '';
        //     if (this.form.reverse) {
        //         let active_currency = _.find(this.currencies, 'is_active');
        //         if (active_currency) {
        //             str = active_currency.symbol;
        //         }
        //     } else {
        //         str = this.form.symbol;
        //     }
          
        // },
        // getAppendText() {
        //     let str = '';
        //     if (!this.form.reverse) {
        //         let active_currency = _.find(this.currencies, 'is_active');
        //         if (active_currency) {
        //             str = active_currency.symbol;
        //         }
        //     } else {
        //         str = this.form.symbol;
        //     }
        // }
    },
};
</script>
<style lang="scss">
  .el-input-group{
    .el-input__inner{
      border-radius: 10px 0 0 10px !important;
    }
  }
</style>
